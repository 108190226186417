import cx from "classnames"
import { useState } from "react"
import { usePropsObject } from "@/lib/contexts/appContext"
import ImageVideo from "@/common/partials/imageVideo"
import styles from "./video-teasers.module.css"
import Block from "@/components/wrappers/block"
import TextVideoBlock from "@/common/partials/textVideoBlock/textVideoBlock"
import ArticleContent from "@/common/article/content"
import Button from "@/common/button/button"

export default function VideoTeasers({ block }) {
  const { button, videos, heading } = block.attrs
  const [hovered, setHovered] = useState(false)
  if (videos.length === 0) {
    return null
  }

  let mainArticle
  let blurbVideos = []
  videos.forEach((video, index) => {
    if (index === 0) {
      mainArticle = video
    } else {
      blurbVideos.push(video)
    }
  })

  return (
    <Block width="bleed" block={block}>
      <TextVideoBlock
        headline={heading}
        gap={blurbVideos.length > 0 ? "none" : "normal"}
        layout="right"
        hovered={hovered}
        setHovered={setHovered}
        data={{
          url: mainArticle.video,
          poster: {
            url: mainArticle.image.url,
            alt: mainArticle.image.alt
          }
        }}
        className={styles.mainArticle}>
        <ArticleContent
          article={mainArticle}
          hovered={hovered}
          setHovered={setHovered}
        />
        {blurbVideos.length === 0 && button.url && (
          <Button
            theme={button.theme ?? "lime"}
            tagName="a"
            href={button.url}
            target={button.target}
            className={styles.articleButton}>
            {button.label}
          </Button>
        )}
      </TextVideoBlock>
      {blurbVideos.length > 0 && (
        <div className={styles.plate}>
          <div className={styles.wrapper}>
            {blurbVideos.map((article, index) => {
              return (
                <VideoBlurb key={`blurbVideo-${index}`} article={article} />
              )
            })}
          </div>
          {button?.url && (
            <div className={styles.buttonWrapper}>
              <Button
                theme={button.theme ?? "light-lime"}
                tagName="a"
                href={button.url}
                target={button.target}
                className={styles.button}>
                {button.label}
              </Button>
            </div>
          )}
        </div>
      )}
    </Block>
  )
}
const VideoBlurb = ({ article }) => {
  const [hovered, setHovered] = useState(false)
  const { theme } = usePropsObject()
  article.tag = false
  return (
    <div
      className={cx({
        "text-white": theme?.txtColor === "white"
      })}>
      <ImageVideo
        data={article}
        className={`block mb-3`}
        hovered={hovered}
        aspectRatio="aspect-w-16 aspect-h-12"
        onMouseLeave={() => {
          setHovered(false)
        }}
        onMouseEnter={() => {
          setHovered(true)
        }}
      />
      <ArticleContent
        article={article}
        hovered={hovered}
        setHovered={setHovered}
      />
    </div>
  )
}
